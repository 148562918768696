import { Header, Loader, ScreenSkelton } from '@storybook';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Breadcrumb } from 'components/bread-crumb';
import { APIS, API_TYPE } from 'constants/api';
import { useFullStoryTrackEvent, useNextStep, useSharedVariables } from 'hooks';
import {
	BYPASS_STEPS,
	BY_PSSS_URL,
	CUSTOM_ORDER_STEPS,
	ExtraComplexSteps,
	ExtraSteps,
	ExtraStepsDoLater,
	ExtraStepsQR,
	IGNORE_METADATA_KEYS,
	PREVENT_BREADCRUMB_FOR_STEPS,
	dolaterSuccess,
} from './constants';
import { useKycRequests } from './kyc/stores';
import { useSignAgreementRequests } from './sign-agreement/stores';

import {
	AccessTokenState,
	QrStatusCodeState,
	WebComponentMetaDataState,
} from 'states';
import { isShowSkipState } from 'states/kyc';
import { UrlPathState } from 'states/url-path';
import { getOrigin, paramsToObject, urlPath } from 'utils/url';
import { activeScreenState } from './facial-enroll/states';
import { IsInvestingAmountEmptyState } from './fund-investment/stores';
import { LinkExpired } from './link-expired';

import { ErrorPage } from 'components/error-page';
import { FlowType } from 'constants/common';
import { ISessionDetails } from 'hooks/use-next-step/stores/types';
import { CameraPermisionsErrors } from './facial-enroll/constant';
import { ComponentRoot } from 'component-root';
import { ComponentType } from 'component-root/stores/types';

import './main.scss';
import { useTokenSession } from 'hooks/token/token-session';
import { checkHexaDecimal } from 'utils';
import { STEPS } from 'routes';
import {
	SessionDetailsState,
	userBuisnessIdState,
} from 'hooks/use-next-step/stores';
import {
	forceOpen,
	QuestionnireState,
	signAgreementState,
} from './kyc/constants';
import { InternetConnectionIssue } from './internet-connection-speed/internet-connection-issue';
import { ShowQrPageState } from './qr-page';
import { AllowedToDoItLater } from 'constants/kyb-form';
import { EVENTS_TRACKING, timestamp, useTrackEvents } from 'helpers';
import classNames from 'classnames';
const enableCameraSetting = true;
interface IMain {
	handleChange?: (e: any) => void;
	onComplete?: (e: string) => void;
	steps?: string[];
	termsCondition?: boolean;
	biometric?: boolean;
	fromSignup?: boolean;
}

//@avinash : for now setting undefined for process but need to modify this
(window as any).process = undefined;
export const Main: React.FC<IMain> = ({ onComplete, handleChange, steps }) => {
	const shouldShowSkip = useRecoilValue(isShowSkipState);
	const setIsInvestingAmountEmpty = useSetRecoilState(
		IsInvestingAmountEmptyState
	);
	const activeScreen = useRecoilValue(activeScreenState);
	// const [statusCode, setStatusCode] = useState(200);
	const [tokenLoading, setTokenLoading] = useState(false);
	const { apiEndPoint: API_HOST } = useSharedVariables();
	const [{ fromSignup = false }, setWebComponentMetaData] = useRecoilState(
		WebComponentMetaDataState
	);

	const setShowQr = useSetRecoilState(ShowQrPageState);
	const sessionDetail = useRecoilValue(SessionDetailsState);

	const {
		setSessionDetails,
		sessionPayloadDetail,
		sessionDetails,
		currentStep,
		setProvider,
	} = useNextStep();
	const {
		getComplexSession: complexSessionType,
		getKybForm,
		nodeIdFromQueryParam,
		onboardingType,
		allParam,
		getKycQr,
	} = useSharedVariables();

	const { postTokenSession } = useTokenSession();

	const {
		postTokenSession: getSessionDetails,
		loading: sessionFetchingLoader,
	} = useTokenSession();
	const setPathUrl = useSetRecoilState(UrlPathState);
	const setUserBuisnessId = useSetRecoilState(userBuisnessIdState);

	const { requestSignAgreeURLWebWorker } = useSignAgreementRequests();
	const { requestTokenWebWorker } = useKycRequests();
	const { handleNext } = useNextStep();
	const { trackEvents } = useTrackEvents();

	const { colorScheme } = useMemo(
		() => sessionDetails?.organization ?? {},
		[sessionDetails?.organization]
	);

	const { key } = useMemo(
		() => sessionPayloadDetail.currentAction ?? {},
		[sessionPayloadDetail.currentAction]
	);
	const { stepId, sessionId, getComplexSession } = useSharedVariables();

	const [isLoading, setIsLoading] = useState(false);
	const [qrStatusCode, setQrStatusCode] = useRecoilState(QrStatusCodeState);
	const { token: accessToken, code } = useRecoilValue(AccessTokenState);
	const { trackEvent } = useFullStoryTrackEvent();

	const fetchSessionData = useCallback(async () => {
		if (onboardingType === FlowType.QR) {
			setIsLoading(true);
			const customConfig = {
				headers: {
					domain: getOrigin(),
				},
			};
			setTokenLoading(true);
			const qrResponse = await postTokenSession({
				type: API_TYPE.QR,
				apiHost: API_HOST,
				customConfig: customConfig,
			});

			setTokenLoading(false);
			setSessionDetails(prev => ({
				...prev,
				organization: {
					...(qrResponse?.organization ?? {}),
					business: qrResponse?.pipeline?.business ?? '',
				},
			}));

			const { statusCode } = qrResponse ?? {};
			setQrStatusCode(statusCode);
			trackEvents('qr-details', { ...(sessionDetail ?? {}) });
		}
		setIsLoading(false);
	}, [
		onboardingType,
		postTokenSession,
		setQrStatusCode,
		setSessionDetails,
		API_HOST,
		trackEvents,
		sessionDetail,
	]);

	useEffect(() => {
		if (colorScheme?.brandColor) {
			document.documentElement.style.setProperty(
				'--color-primary-light',
				colorScheme?.brandColor
			);
		}
	}, [colorScheme?.brandColor]);

	useEffect(() => {
		if (accessToken && checkHexaDecimal(sessionId)) {
			fetchSessionData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken]);

	const currentPath = urlPath();

	useEffect(() => {
		//Paras : added for checking purpose. status code 0 and type remove from url issue.
		// eslint-disable-next-line no-console
		console.log('currentPath', currentPath);
		setPathUrl(currentPath);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLinearFlow = useCallback(
		(response: any) => {
			const { steps } = response.data ?? {};

			if (steps?.length !== 1) {
				steps?.unshift(...ExtraSteps);
			}

			if (/Firefox/.test(navigator.userAgent)) {
				steps?.reverse();
			}

			let sortedSteps;
			const allSteps = steps?.sort((a: any, b: any) => {
				const aIndex = CUSTOM_ORDER_STEPS.indexOf(a.stepId);
				const bIndex = CUSTOM_ORDER_STEPS.indexOf(b.stepId);
				if (aIndex === -1 || bIndex === -1) {
					return aIndex === -1 ? 1 : -1;
				}
				return aIndex - bIndex;
			});

			if (BY_PSSS_URL.includes(document.referrer)) {
				sortedSteps = allSteps.filter(
					(item: any) => !BYPASS_STEPS.includes(item.stepId)
				);
			} else if (getComplexSession) {
				sortedSteps = allSteps.filter(
					(item: any) => !['term-condition'].includes(item.stepId)
				);
			} else sortedSteps = steps;

			const updatedPayload = {
				...response.data,
				steps: sortedSteps,
			};
			const { investingAmount } = updatedPayload ?? {};
			setIsInvestingAmountEmpty(!investingAmount || investingAmount === 0);
			setSessionDetails(updatedPayload);

			const { provider } = response.data?.agreements?.[0] ?? {};
			if (provider) {
				setProvider(provider?.toLowerCase());
			}
		},
		[
			getComplexSession,
			setIsInvestingAmountEmpty,
			setProvider,
			setSessionDetails,
		]
	);

	const handleComplexFlow = useCallback(
		(response: any) => {
			const updatedResponse = {
				...response.data,
				steps: getComplexSession ? ExtraComplexSteps : ExtraSteps,
			};

			const { investingAmount } = updatedResponse ?? {};
			setIsInvestingAmountEmpty(!investingAmount || investingAmount === 0);

			setSessionDetails(updatedResponse);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[getComplexSession, setIsInvestingAmountEmpty, setSessionDetails]
	);

	const initializeFullStorySession = useCallback(
		(userData: ISessionDetails | Record<string, any>) => {
			const {
				name,
				countryCode,
				phone,
				email,
				createdAt,
				_id,
				code,
				billingType = '',
				organization,
			} = userData ?? {};

			const userName = name?.split(' ');

			const properties = {
				user: {
					firstName: userName[0],
					lastName: userName[1],
					phone: `${countryCode} ${phone}`,
					email,
					subscriptionType: billingType,
					businessName: organization?.name ?? '',
				},
				sessionCreatedAt: createdAt,
				sessionId: code,
				onboardingFlow:
					onboardingType === FlowType.Complex
						? FlowType.Complex
						: FlowType.Linear,
				type: onboardingType === FlowType.QR ? FlowType.QR : FlowType.Invite,
				createdAt: new Date(),
			};

			trackEvent('session_started', properties);

			(window as any).FS?.identify?.(_id);
			(window as any).FS?.setUserVars?.({
				displayName: name,
				email,
			});
		},
		[onboardingType, trackEvent]
	);

	const handleQrKyc = useCallback(
		async (prevData: any) => {
			// Prepare payload with user and pipeline details, as well as node and redirection information
			const payload = {
				userId: prevData?.userId,
				pipelineId: prevData?._id,
				nodeId: nodeIdFromQueryParam,
				redirectStepId: 'kyc',
			};

			// Send the session token request using the prepared payload and session code
			const resp = await postTokenSession({ payload, code: sessionId });

			// If the response status is successful, update session details and hide the KYC QR code
			setSessionDetails(prev => ({
				...prev,
				...prevData,
				nodes: resp.stepId === 'kyc' ? resp : dolaterSuccess,
			}));
			setShowQr(false);

			// Return the response for further handling if necessary
			return resp;
		},
		[
			nodeIdFromQueryParam,
			postTokenSession,
			sessionId,
			setSessionDetails,
			setShowQr,
		]
	);

	useEffect(() => {
		if (accessToken && !sessionDetails?._id) {
			if (onboardingType !== 'qr') {
				let url = `${APIS.TOKEN}`;
				if (stepId) {
					url = `${APIS.TOKEN}?stepId=${stepId}`;
				}
				getSessionDetails({
					url,
					code,
					type: 'code',
				}).then((response: any) => {
					if (response) {
						if (response.statusCode === 200) {
							const resp = { data: response };
							if (resp?.data) {
								setUserBuisnessId(
									resp?.data?.businessId ?? resp?.data?.business ?? ''
								);
								const data = {
									sessionId: code,
									verification_method: 'captable',
								};
								trackEvents(EVENTS_TRACKING.VERIFICATION_STARTED, {
									...data,
									businessId: resp?.data?.businessId,
									timestamp: timestamp,
								});
							}
							const fsURLS = (window as any)?.__ENV?.REACT_APP_FS_URLS;
							const allowedDNS = fsURLS
								? fsURLS
								: ['secure.simplici.io', 'join.morninglineclub.com'];
							// Integrate Full story for
							if (allowedDNS.includes(window.location.host)) {
								initializeFullStorySession(resp?.data ?? {});
							}

							if (onboardingType === 'complex') {
								if (getKycQr && resp?.data?._id) {
									handleQrKyc(resp?.data);
									return;
								}
								if (getKybForm && resp?.data?._id) {
									const payload = {
										pipelineId: resp?.data?.pipelineId,
										userId: resp?.data?.userId,
										nodeId: nodeIdFromQueryParam,
										getKybForm: getKybForm,
									};

									postTokenSession({
										code,
										type: 'update',
										payload,
									}).then((res: any) => {
										if (res?.stepId) {
											const resDolater = {
												...resp,
												data: {
													...resp.data,
													nodes: {
														...ExtraStepsDoLater[0],
														actions: res?.actions ?? [],
														key: res?.key,
													},
													existingKybFormAction:
														res?.existingKybFormAction?.data?.survey[
															Object.keys(
																res?.existingKybFormAction?.data?.survey ?? {}
															)?.[0] ?? ''
														],
													questions: Object.keys(
														res?.existingKybFormAction?.data?.survey
													)?.[0],
												},
											};
											handleComplexFlow(resDolater);
											return;
										} else if (getKybForm) {
											setSessionDetails(prev => ({
												...prev,
												nodes: dolaterSuccess,
											}));
										}
									});
								}

								setProvider(resp?.data?.agreements?.[0]?.provider);
								handleComplexFlow(resp);
								return;
							}
							// handleComplexFlow(resp)
							handleLinearFlow(resp);
						}
					}
				});
			} else {
				const updatedPayload: any = {
					steps: ExtraStepsQR,
				};
				const fsURLS = (window as any)?.__ENV?.REACT_APP_FS_URLS;
				const allowedDNS = fsURLS
					? fsURLS
					: ['secure.simplici.io', 'join.morninglineclub.com'];
				if (allowedDNS.includes(window.location.host)) {
					initializeFullStorySession({
						name: code ?? onboardingType,
						countryCode: '',
						phone: '',
						email: 'qr@satschel.com',
						createdAt: new Date(),
						_id: code!,
						code,
						billingType: '',
					});
				}
				setSessionDetails(updatedPayload);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken, code, stepId]);

	useEffect(() => {
		if (sessionDetails?.steps?.length !== 0) {
			const { steps } = sessionDetails ?? {};
			if (steps?.[currentStep + 1]?.stepId === 'signAgreement')
				requestSignAgreeURLWebWorker();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionDetails?.steps, currentStep]);

	useEffect(() => {
		if (sessionDetails?.steps?.length !== 0) {
			const steps = sessionDetails?.steps?.map(res => res.stepId);
			if (steps?.includes('kyc')) requestTokenWebWorker();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionDetails?.steps]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getKey = (key: ComponentType): ComponentType => {
		if (key !== 'kybForm') {
			return key;
		}
		if (onboardingType === FlowType.Complex) {
			return key;
		} else {
			return 'form';
		}
	};

	useEffect(() => {
		// Exit early if 'key' is falsy or 'handleChange' is not a function
		if (!key || typeof handleChange !== 'function') return;

		// Call 'handleChange' with updated session details
		handleChange({
			...sessionPayloadDetail?.currentAction,
			code,
			sessionId: sessionPayloadDetail?.sessionId ?? '', // Ensure sessionId is a string
		});

		// Destructure relevant fields from sessionPayloadDetail for easier access
		const {
			stepsId = '',
			sessionType = '',
			investingAmount = 0,
		} = sessionPayloadDetail ?? {};

		// Define condition for invalid steps based on business logic
		const isInvalidStep =
			stepsId !== STEPS.Authentication && // Must not be Authentication step
			(!steps?.includes(stepsId) || // Step ID is not in the allowed steps
				(stepsId === 'fundInvestment' &&
					sessionType === 'buyer' &&
					!investingAmount)); // Special case for fundInvestment step

		// If steps exist and the step is invalid, trigger onComplete callback
		if (steps && isInvalidStep && typeof onComplete === 'function') {
			onComplete(sessionPayloadDetail.sessionId);
		}

		// If the key indicates success screen completion, trigger onComplete callback
		if (
			key === 'successScreenCompletion' &&
			sessionDetails?.isSessionCompleted &&
			typeof onComplete === 'function'
		) {
			setTimeout(() => {
				onComplete(sessionPayloadDetail.sessionId);
			}, 2000);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key, sessionDetails?.isSessionCompleted]); // Dependency array only includes 'key' as others come from sessionPayloadDetail

	// Memoize the check to see if `businessId` is in the `AllowedToDoItLater` array
	const isDoItLater = useMemo(
		() => AllowedToDoItLater.includes(sessionPayloadDetail?.businessId ?? ''),
		[sessionPayloadDetail?.businessId]
	);

	const renderMainComponent = useMemo(() => {
		if (!enableCameraSetting) {
			return (
				<ComponentRoot
					componentKey="cameraSetting"
					componentProps={{
						cameraTypeError: CameraPermisionsErrors.Camera_settings_OFF,
					}}
				/>
			);
		} else if (getKybForm) {
			if (
				sessionDetails?.existingKybFormAction ||
				sessionDetails?.nodes?.actions?.[0]?.key === 'successScreenCompletion'
			) {
				if (/successScreenCompletion|kybForm/.test(key) || isDoItLater) {
					return (
						<ComponentRoot
							componentKey={key as 'successScreenCompletion' | 'kybForm'}
						/>
					);
				}
			} else {
				return <ComponentRoot componentKey={'default'} />;
			}
		} else {
			return <ComponentRoot componentKey={getKey(key as ComponentType)} />;
		}
		return null;
	}, [
		getKybForm,
		sessionDetails?.existingKybFormAction,
		sessionDetails?.nodes?.actions,
		key,
		getKey,
		isDoItLater,
	]);

	const shouldShow = useMemo(() => {
		if (shouldShowSkip) {
			return key;
		}
		return undefined;
	}, [key, shouldShowSkip]);

	useEffect(() => {
		const params = new URLSearchParams(window ? window.location.search : {});
		if (params.get('isfromsignup')) {
			setWebComponentMetaData(prev => ({ ...prev, fromSignup: true }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			accessToken &&
			(complexSessionType === 'complexSession' ||
				onboardingType === FlowType.Complex)
		) {
			const searchParams = new URLSearchParams(allParam);
			const entries = searchParams.entries(); //returns an iterator of decoded [key,value] tuples
			const params = paramsToObject(entries, IGNORE_METADATA_KEYS);
			postTokenSession({ payload: params, type: API_TYPE.METADATA });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [complexSessionType]);

	const skeletonHeader = useMemo(() => {
		if (!enableCameraSetting || activeScreen === 'facial-enroll') {
			return <></>;
		}
		return (
			<Fragment>
				{!fromSignup ? (
					<Header
						logo={sessionDetails?.organization?.logo}
						loading={sessionFetchingLoader || tokenLoading}
						currentKey={shouldShow}
						handleSkip={handleNext}
					/>
				) : null}
				{!PREVENT_BREADCRUMB_FOR_STEPS.includes(key) &&
					onboardingType !== FlowType.Complex && (
						<Breadcrumb ExtraSteps={getComplexSession ? [] : ExtraSteps} />
					)}
			</Fragment>
		);
	}, [
		activeScreen,
		tokenLoading,
		fromSignup,
		sessionDetails?.organization?.logo,
		sessionFetchingLoader,
		shouldShow,
		handleNext,
		key,
		onboardingType,
		getComplexSession,
	]);

	const errorScreens: any = {
		200: renderMainComponent ?? <div></div>,
		400: <LinkExpired />,
		0: <InternetConnectionIssue />,
	};

	const isAccriditation = useMemo(() => forceOpen.includes(key), [key]);

	const isQuestionnire = useMemo(() => QuestionnireState.includes(key), [key]);

	const isSignAgreement = useMemo(
		() => signAgreementState.includes(key),
		[key]
	);

	const inLiquidityIframe = useMemo(
		() =>
			window.self !== window.top &&
			/Liquidity/.test(sessionDetail?.organization?.name ?? ''),
		[sessionDetail?.organization?.name]
	);

	const mobileViewClass = classNames('mobile-view', {
		'acc-mobile-view': isAccriditation,
		'iframe-mobile-view': inLiquidityIframe,
	});

	const renderPage = useMemo(() => {
		// if (statusCode !== 200) {
		// 	return <ErrorPage status={statusCode as 500} />;
		// }
		return (
			<div className={mobileViewClass}>
				<ScreenSkelton
					header={skeletonHeader}
					isQuestionnire={isQuestionnire || isSignAgreement}
					body={
						isLoading ? (
							<Loader />
						) : (
							errorScreens[qrStatusCode] ?? (
								<ErrorPage status={qrStatusCode as any} />
							)
						)
					}
				/>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, qrStatusCode, skeletonHeader]);

	return <Fragment>{renderPage}</Fragment>;
};
