import { FlowType } from 'constants/common';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { AccessTokenState, WebComponentMetaDataState } from 'states';
import { checkHexaDecimal } from 'utils';
import { REACT_APP_API_HOST as API_ENDPOINT, REACT_APP_GLARE_API } from 'envs';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import { hardCodedImage } from './constant';

type OnboardingType = 'complex' | 'qr' | 'mobile' | any;
type KybFormType = true | false | null;
type IKycQRType = true | false | null;
type SessionType = string | null;

type IAllowRedirect = 'true' | 'false' | null;

interface ISharedVariables {
	sessionId: string;
	stepId: string | null;
	hideAppleId: string | null;
	onboardingType: OnboardingType;
	userId: string | null;
	windowReload: () => void;
	currentUrl: string;
	getKybForm: KybFormType;
	getComplexSession: SessionType;
	nodeIdFromQueryParam: string | null;
	qrAllowInvite: boolean | null;
	paramSessionId: any;
	apiEndPoint: string;
	envHost?: string;
	allParam?: string;
	glareUrl: string;
	allowRedirect: IAllowRedirect;
	urlSearchParams: URLSearchParams;
	getKycQr: IKycQRType;
	getAuthQr: IKycQRType;
	hostUrl: () => string;
	connectionId: string | null;
	isSkipAuthVariable?: { skipAuth: boolean; image: string };
	createNewSession: string | null; // Add this line
}

export const useSharedVariables = (): ISharedVariables => {
	const { code, type = FlowType.Complex } = useRecoilValue(AccessTokenState);
	const { isWebComponent } = useRecoilValue(WebComponentMetaDataState);

	const sessionDetail = useRecoilValue(SessionDetailsState);
	const { userDetail } = sessionDetail ?? {};
	const { investorData } = userDetail ?? {};

	const { apiEndPoint: endpointUrl, glareUrl = REACT_APP_GLARE_API } =
		useRecoilValue(WebComponentMetaDataState);

	const apiEndPoint = endpointUrl ? endpointUrl : API_ENDPOINT;

	const isStage = /stage/gi.test(apiEndPoint);
	const isPP = /pp/gi.test(apiEndPoint);
	const isProd = /api.satschel.com|api.simplici.io/gi.test(apiEndPoint);
	const isBeta = /beta/gi.test(apiEndPoint);

	const environmentHost = () => {
		if (isStage) return 'stage';
		if (isPP) return 'preprod';
		if (isProd) return 'prod';
		if (isBeta) return 'beta';
		return 'stage';
	};

	const envHost = environmentHost();

	const windowReload = () => window.location.reload();

	const currentUrl = window.location.href;
	const urlParams = new URL(currentUrl);
	const allParam = currentUrl.split('?')[1];

	const paramSessionId = !isWebComponent
		? urlParams.pathname.split('/')[1]
		: null;

	const sessionId = useMemo(() => {
		if (code?.length) {
			return code;
		}
		return paramSessionId ?? '';
	}, [code, paramSessionId]);
	const urlSearchParams = new URLSearchParams(window.location.search);
	const stepId = urlSearchParams.get('stepId');
	const createNewSession = urlSearchParams.get('createNewSession'); // Add this line
	const onboardingType = (() => {
		const isQRFlow = checkHexaDecimal(sessionId ?? '');

		if (isWebComponent) {
			return isQRFlow ? FlowType.QR : FlowType.Complex;
		}

		const typeFromUrl = urlSearchParams.get('type');
		return typeFromUrl || (isQRFlow ? FlowType.QR : type);
	})();

	if (
		!isWebComponent &&
		!urlSearchParams.get('type') &&
		checkHexaDecimal(sessionId ?? '')
	) {
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set('type', 'qr');
		window.history.replaceState(
			{},
			'',
			`${window.location.pathname}?${searchParams.toString()}`
		);
	}

	const hideAppleId = urlSearchParams.get('hideAppleID');
	const userId = urlSearchParams.get('userID');
	const getKybForm = urlSearchParams.get('getKybForm') as KybFormType;
	const getKycQr = urlSearchParams.get('getKycQr') as IKycQRType;
	const getAuthQr = urlSearchParams.get('getAuthQr') as IKycQRType;
	const getComplexSession = isWebComponent
		? checkHexaDecimal(sessionId)
			? null
			: 'complexSession'
		: (urlSearchParams.get('session') as SessionType);
	const nodeIdFromQueryParam = urlSearchParams.get('nodeId');
	const qrAllowInvite = urlSearchParams.get('allow') === 'true';
	const allowRedirect = urlSearchParams.get('redirect') as IAllowRedirect;
	const connectionId = urlSearchParams.get('connectionId');

	// Function to determine the host URL based on the environment
	const hostUrl = () => {
		// Return stage URL if `isStage` environment is active
		if (isStage) return 'https://secure.stage.satschel.com';

		// Return pre-production URL if `isPP` environment is active
		if (isPP) return 'https://secure.pp.satschel.com';

		// Return production URL if `isProd` environment is active
		if (isProd) return 'https://secure.simplici.io';

		// Return beta URL if `isBeta` environment is active
		if (isBeta) return 'https://secure.beta.simplici.io';

		// Default to stage URL if no environment matches
		return 'https://secure.stage.satschel.com';
	};

	const valueToCheck = [
		'shubham.shukla@satschel.com',
		'arun.kumar@satschel.com',
		'8283847779',
		'7349235825',
	];

	const isSkipAuthVariable = useMemo(() => {
		const isSkipAuth = urlSearchParams.get('skip-auth');

		if (isSkipAuth === 'true') {
			return { skipAuth: true, image: '' };
		} else if (investorData) {
			const matches = valueToCheck.some(value =>
				(investorData ?? []).includes(value)
			);
			return {
				skipAuth: matches,
				image: hardCodedImage,
			};
		} else {
			return { skipAuth: false, image: '' };
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investorData]);

	return {
		isSkipAuthVariable,
		sessionId,
		stepId,
		hideAppleId,
		onboardingType,
		userId,
		windowReload,
		currentUrl,
		getKybForm,
		getComplexSession,
		nodeIdFromQueryParam,
		qrAllowInvite,
		paramSessionId,
		apiEndPoint,
		envHost,
		allParam,
		glareUrl,
		allowRedirect,
		urlSearchParams,
		getKycQr,
		hostUrl,
		connectionId,
		getAuthQr,
		createNewSession,
	};
};
